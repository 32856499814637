
.table-transaction-detail {
  td {
    padding-top: 10px;

  }
  .transaction-detail-col-1 {
    width: 180px;
  }
  .transaction-detail-col-2 {
      font-weight: 600;
  }
}