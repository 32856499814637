.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left {
    &--title {
      font-size: 24px !important;
      line-height: 40px !important;
      font-weight: bold;
      color: var(--header-title-color);
    }
    &--subtitle {
      color: var(--header-subtitle-color);
    }
  }
}