
  .profile-avatar {
    align-content: center;
    max-width: 12em;
    max-height: 12em;
    border-radius: 2px;
    position: relative;
    top: .35em;
    box-shadow: 0px 0px 2px #ccc;
  }

  .profile-name {
    padding-top: 0px;
    max-width: 12em;
    max-height: 12em;
    text-align: left;
    font-size: 1.2em;
    font-weight: 500;
    color: darkblue;
    word-wrap: break-word;
  }

  td {
    padding-top: .3em;
    padding-right: .5em;
  }

  .content-label{
    width: 170px;
  }

  .ant-image-preview-img{
    background-color: #fff;
  }