.permission-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 360px;
  }
  .content {
    font-size: 20px;
    margin-top: 24px;
  }
  .note {
    margin: 12px;
    font-size: 16px;
    font-weight: bold;
  }
}