.ant-layout {
  background: none !important;
}
.ant-layout-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: none !important;
  height: calc(var(--header-height) + var(--default-spacing) * 2) !important;
  background-color: var(--background-layout-color) !important;
  padding: var(--default-spacing) !important;
}

.ant-layout-sider {
  background: none !important;
}

.ant-pagination {
  margin-top: 12px;
  text-align: end;
}

.ant-table-content {
  // overflow: auto !important;
  table {
    width: 100% !important;
  }
}

.ant-card {
  .ant-card-head {
    .ant-card-head-title {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.ant-tag {
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  width: max-content;
}

.apexcharts-legend-series {
  display: flex;
  align-items: center;
  margin-bottom: 20px !important;
  .apexcharts-legend-marker {
    margin-right: 8px !important;
  }
}