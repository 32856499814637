.upload-image-component {
    img {
        width: 100%;
        height: 100%;
        resize: horizontal;
        //width: 90px;
        //height: calc(100px * 9 / 16);
    }
}
.ant-upload{
    overflow: hidden !important;
}