.admin-layout {
  position: relative;
  min-height: 100vh;
  height: max-content;
  background-color: var(--background-layout-color) !important;
  .admin-layout-center {
    background: none !important;
    .admin-layout-content {
      background-color: var(--background-layout-color) !important;
      position: absolute;
      left: calc(var(--sidebar-width));
      top: calc(var(--header-height) + var(--default-spacing));
      width: calc(100% - var(--sidebar-width));
      padding: var(--default-spacing) var(--default-spacing) var(--default-spacing) 0;
      min-height: calc(100vh - var(--header-height) - var(--default-spacing));
      &.full-page {
        left: var(--default-spacing);
        width: calc(100% - var(--default-spacing));
      }
      .admin-layout-content-data {
        border-radius: 12px;
        padding: calc(var(--default-spacing) * 1.5);
        background-color: var(--background-page-color);
        min-height: calc(100vh - var(--header-height) - var(--default-spacing) * 3);
      }
    }
  }
}