.ant-layout-sider {
  flex: 0 0 var(--sidebar-width) !important;
  max-width: max-content !important;
  position: fixed !important;
  width: var(--sidebar-width) !important;
  padding: var(--default-spacing) !important;
  top: calc(var(--header-height) + var(--default-spacing));
  background-color: var(--background-layout-color) !important;
  height: 100%;
  .ant-layout-sider-children {
    width: calc(var(--sidebar-width) - var(--default-spacing) * 2) !important;
    background: none;
    height: 100%;
    overflow: auto;
    padding-bottom: 100px;
    span {
      //color: var(--menu-color);
      font-weight: 500;
    }


    //.admin-layout-sidebar {
    //  background-color: rgba(245, 245, 245, 0);
    //  width: calc(var(--sidebar-width) - var(--default-spacing) * 2) !important;
    //}
    //.ant-menu {
    //  background-color: rgba(245, 245, 245, 0);
    //  height: 100%;
    //  border-inline-end: none !important;
    //
    //  //.ant-menu-item {
    //  //  padding-left: var(--default-spacing) !important;
    //  //}
    //
    //  .ant-menu-sub {
    //    background: none !important;
    //  }
    //  .ant-menu-submenu {
    //    &.ant-menu-submenu-selected {
    //      .ant-menu-submenu-title {
    //        span,
    //        i {
    //          color: var(--link-color) !important;
    //        }
    //      }
    //    }
    //    //.ant-menu-submenu-title {
    //    //  padding-left: var(--default-spacing) !important;
    //    //}
    //    //.ant-menu-item {
    //    //  padding-left: calc(var(--default-spacing) * 2 ) !important;
    //    //}
    //  }
    //  .ant-menu-item,
    //  .ant-menu-submenu-title {
    //    width: 100%;
    //    margin: 0 0 6px 0 !important;
    //    span {
    //      color: var(--menu-color);
    //      font-weight: 500;
    //    }
    //    &:hover {
    //      background-color: var(--background-page-color) !important;
    //    }
    //  }
    //
      .ant-menu-item-selected {
        background-color: var(--background-page-color) !important;
        span {
          color: var(--link-color) !important;
        }
      }
    //}
  }
}

//.ant-menu-root > li  {
//    text-transform: uppercase;
//}