.login-page {
	height: 100vh;
	width: 100vw;
	background-image: linear-gradient(180deg, #FFB7A0, #391A5F);
	display: flex;
	justify-content: center;
	align-items: center;

	.page-content {
		width: 350px;
		max-width: calc(100% - 20px);

		&__top {
			background-color: #391A5F;
			padding: 12px 36px;
			text-transform: uppercase;

			.ant-row {
				justify-content: space-between;
			}

			&__right {
				display: flex;
				align-items: center;

				.ant-divider-vertical {
					background: #FFFDFD;
					height: 100%;
					margin-right: 24px;
				}

				.text {
					font-size: 24px;
					font-weight: 500;
					background: linear-gradient(180deg, #FFB7A0 99.99%, rgba(255, 183, 160, 0) 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
				}
			}
		}

		&__bottom {
			background-color: #ffff;
			padding: 24px;

			.btn-submit {
				background-color: #391A5F;
				border: 0px;
				width: 100%;
				margin-top: 8px;
				color: #FFB7A0;
			}
		}
	}
}