.admin-layout-header {
  padding: calc(var(--default-spacing) / 2) var(--default-spacing);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  line-height: var(--header-height);
  background-color: var(--background-header-color) !important;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  position: fixed;
  z-index: 1000;
  width: calc(100% - 32px);
  .admin-layout__logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-image {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-logo {
        height: 100% !important;
      }
      .icon-name-logo {
        margin-left: 10px;
      }
    }
  }
}