
.table-settle-detail {
  td {
    padding-top: 10px;

  }
  .item-detail-col-1 {
    width: 160px;
  }
  .force-settle-detail-col-2 {
      font-weight: 600;
  }
}