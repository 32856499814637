
.table-transaction-detail {
  td {
    padding-top: 10px;

  }
  .transaction-detail-col-1 {
    width: 150px;
  }
  .transaction-detail-col-2 {
      font-weight: 600;
  }
}

.dashboard-card-savings-transactions-1{
  background-color: rgba(46,204,113,1);
}

.dashboard-card-savings-transactions-2{
  background-color: rgba(52,152,219,1);
}

.savings-transactions{
  .dashboard-card{
    box-shadow: none;
    color: #fff
  }
}