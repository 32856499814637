.w-full {
  width: 100% !important; 
}
 
 .filter {
  margin-top: var(--default-spacing);
  .filter-filed {
    min-width: 250px;
    width: max-content;
    max-width: 50%;
  }
}

.statistics {
  &__item {
    .ant-card-body {
      display: flex;
      padding: 4px 12px !important;
    }
    &--label {
      font-size: 12px;
    }
    &--total {
      font-weight: bold;
      margin-left: 6px;
    }
  }
}

.report-system {
  &__item {
    height: 100%;
    &--label {
      font-size: 18px;
      font-weight: bold;
    }
    &--value {
    }
  }
}
 
.table-data {
  margin-top: var(--default-spacing);
}

.detail-footer {
  margin-top: 24px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

@for $i from 1 through 10 {
  .w-#{$i}0 {
    width: #{$i}0% !important;
  }

  .w-#{$i}5 {
    width: #{$i}5% !important;
  }

  .h-#{$i}0 {
    height: #{$i}0% !important;
  }

  .max-width-#{$i}0 {
    width: 100% !important;
    max-width: #{$i}0% !important;
  }
}

* {
  word-break: break-word !important;
}

.border-none {
  border: none !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.flex-1 {
  flex: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-fullwidth-item {
  flex: 0 0 100%;
}

.flex-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.text-decoration-revert {
  text-decoration: revert !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-transform-initial {
  text-transform: initial !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.order-first {
  order: -1 !important
}

.order-0 {
  order: 0 !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.order-3 {
  order: 3 !important
}

.order-4 {
  order: 4 !important
}

.order-5 {
  order: 5 !important
}

.order-last {
  order: 6 !important
}