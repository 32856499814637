.logo-app {
    display: flex;
	align-items: center;

    .icon-logo {
        height: 45px;
        margin-right: 6px;
    }

    .text-logo {
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 300;
        background: linear-gradient(180deg, #FFB7A0 99.99%, rgba(255, 183, 160, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        &--bold {
            font-weight: 500;
        }
    }
}