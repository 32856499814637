$body-font: "Roboto";
$icon-font: "bf-icon";
$--font-regular: 400;
$--font-medium: 500;
$--font-bold: 700;

@font-face {
  font-family: $body-font;
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: $--font-regular;
}
@font-face {
  font-family: $body-font;
  src: url("../fonts/Roboto-Medium.ttf");
  font-weight: $--font-medium;
}
@font-face {
  font-family: $body-font;
  src: url("../fonts/Roboto-Bold.ttf");
  font-weight: $--font-bold;
}

body {
  font-family: $body-font, sans-serif !important;
  font-weight: $--font-regular;
}

$--line-height-2xs: 18px;
$--line-height-xs: 20px;
$--line-height-s: 22px;
$--line-height-m: 24px;
$--line-height-l: 28px;
$--line-height-xl: 32px;
$--line-height-2xl: 36px;
$--line-height-3xl: 44px;

$line-height: "2xs" $--line-height-2xs, "xs" $--line-height-xs, "s" $--line-height-s, "m" $--line-height-m,
  "l" $--line-height-l, "xl" $--line-height-xl, "2xl" $--line-height-2xl, "3xl" $--line-height-3xl;

@each $name, $height in $line-height {
  .line-height-#{$name} {
    line-height: $height !important;
  }
}

$--font-2xs: 12px;
$--font-xs: 14px;
$--font-s: 16px;
$--font-m: 18px;
$--font-l: 20px;
$--font-xl: 24px;
$--font-2xl: 28px;
$--font-3xl: 36px;

$--font-height-2xs: 20px;
$--font-height-xs: 24px;
$--font-height-s: 22px;
$--font-height-m: 24px;
$--font-height-l: 28px;
$--font-height-xl: 32px;
$--font-height-2xl: 36px;
$--font-height-3xl: 44px;

$fonts-size: "2xs" $--font-2xs $--font-height-2xs, "xs" $--font-xs $--font-height-xs, "s" $--font-s $--font-height-m,
  "m" $--font-m $--font-height-m, "l" $--font-l $--font-height-l, "xl" $--font-xl $--font-height-xl,
  "2xl" $--font-2xl $--font-height-2xl, "3xl" $--font-3xl $--font-height-3xl;

@each $name, $size, $height in $fonts-size {
  .text-#{$name} {
    font-size: $size !important;
    line-height: $height;
  }
}

$headings: "small" $--font-2xs $--font-height-2xs, "p" $--font-xs $--font-height-xs, "h6" $--font-s $--font-height-s,
  "h5" $--font-m $--font-height-m, "h4" $--font-l $--font-height-l, "h3" $--font-xl $--font-height-xl,
  "h2" $--font-2xl $--font-height-2xl, "h1" $--font-3xl $--font-height-3xl;

@each $name, $size, $height in $headings {
  #{$name} {
    font-size: $size !important;
    line-height: $height;
    margin: 0;
    padding: 0;
  }
}

$fonts-weight: "regular" $--font-regular, "medium" $--font-medium, "bold" $--font-bold;

@each $name, $weight in $fonts-weight {
  .text-#{$name} {
    font-weight: $weight !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-gray {
  color: var(--header-subtitle-color);
}

.text-limit {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 99%;
}