:root {
  --background-header-color: #391A5F  ;
  --background-layout-color: #F7F5F0;
  --background-sidebar-color: #F7F5F0;
  --background-page-color: #FFFFFF;
  --menu-color: #0C0C0D;
  --link-color: ##1677ff;
  --error-color: #fd4e5d;
  --default-color: #4A5568;
  --header-title-color: #391A5F;
  --header-subtitle-color: #8f98a3;
  --primary: ##1677ff;
  --gray: #DADEE3;
}
