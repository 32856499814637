$border-styles: (solid, dashed, dotted, double, hidden, none, outset, inset, groove, ridge);
$border-directions: (top, bottom, left, right, all);
$border-sizes: (1, 2, 3, 4, 5);

$--radius-0: 0;
$--radius-50: 50%;
$--radius-2xs: 4px;
$--radius-xs: 8px;
$--radius-s: 12px;
$--radius-m: 16px;
$--radius-l: 24px;
$--radius-xl: 32px;

$border-radius-directions: (top-left, top-right, bottom-left, bottom-right, all);

$border-radius: 
  "0" $--radius-0,
  "50" $--radius-50,
  "2xs" $--radius-2xs,
  "xs" $--radius-xs,
  "s" $--radius-s,
  "m" $--radius-m,
  "l" $--radius-l,
  "xl" $--radius-xl;


@each $direction in $border-radius-directions {
  @each $name, $radius in $border-radius {
    @if $direction == all {
      .border-radius-#{$name} {
        border-radius: #{$radius} !important;
      }
    } @else {
      .border-#{$direction}-radius-#{$name} {
        border-#{$direction}-radius: #{$radius} !important;
      }
    }
  }
}

@each $direction in $border-directions {
  @each $size in $border-sizes {
    @if $direction == all {
      .border-width-#{$size} {
        border-width: #{$size}px;
      }
    } @else {
      .border-width-#{$direction}-#{$size} {
        border-#{$direction}-width: #{$size}px;
      }
    }
  }
}

@each $direction in $border-directions {
  @each $style in $border-styles {
    @if $direction == all {
      .border-#{$style} {
        border-style: $style;
      }
    } @else {
      .border-#{$direction}-#{$style} {
        border-#{$direction}-style: $style;
      }
    }
  }
}
