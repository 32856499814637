$--spacer-3xs: 2px;
$--spacer-2xs: 4px;
$--spacer-xs: 8px;
$--spacer-s: 12px;
$--spacer-m: 16px;
$--spacer-l: 24px;
$--spacer-xl: 32px;
$--spacer-2xl: 40px;
$--spacer-3xl: 52px;
$--spacer-4xl: 64px;

$spacings: (
  "auto": auto,
  "0": 0,
  "2xs": $--spacer-2xs,
  "xs": $--spacer-xs,
  "s": $--spacer-s,
  "m": $--spacer-m,
  "l": $--spacer-l,
  "xl": $--spacer-xl,
  "2xl": $--spacer-2xl,
  "3xl": $--spacer-3xl,
);

@each $name, $size in $spacings {
  .t-#{$name} {
    top: $size !important;
  }
  .b-#{$name} {
    bottom: $size !important;
  }
  .l-#{$name} {
    left: $size !important;
  }
  .r-#{$name} {
    right: $size !important;
  }
  .m-#{$name} {
    margin-top: $size !important;
    margin-right: $size !important;
    margin-bottom: $size !important;
    margin-left: $size !important;
  }
  .mt-#{$name} {
    margin-top: $size !important;
  }
  .mr-#{$name} {
    margin-right: $size !important;
  }
  .mb-#{$name} {
    margin-bottom: $size !important;
  }
  .ml-#{$name} {
    margin-left: $size !important;
  }
  .mx-#{$name} {
    margin-left: $size !important;
    margin-right: $size !important;
  }
  .my-#{$name} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }
  .p-#{$name} {
    padding-top: $size !important;
    padding-right: $size !important;
    padding-bottom: $size !important;
    padding-left: $size !important;
  }
  .pt-#{$name} {
    padding-top: $size !important;
  }
  .pr-#{$name} {
    padding-right: $size !important;
  }
  .pb-#{$name} {
    padding-bottom: $size !important;
  }
  .pl-#{$name} {
    padding-left: $size !important;
  }
  .px-#{$name} {
    padding-left: $size !important;
    padding-right: $size !important;
  }
  .py-#{$name} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
  @if $name != "auto" and $name != "0" {
    .-t-#{$name} {
      top: -$size !important;
    }
    .-b-#{$name} {
      bottom: -$size !important;
    }
    .-l-#{$name} {
      left: -$size !important;
    }
    .-r-#{$name} {
      right: -$size !important;
    }
    .-m-#{$name} {
      margin-top: -$size !important;
      margin-right: -$size !important;
      margin-bottom: -$size !important;
      margin-left: -$size !important;
    }
    .-mt-#{$name} {
      margin-top: -$size !important;
    }
    .-mr-#{$name} {
      margin-right: -$size !important;
    }
    .-mb-#{$name} {
      margin-bottom: -$size !important;
    }
    .-ml-#{$name} {
      margin-left: -$size !important;
    }
    .-mx-#{$name} {
      margin-left: -$size !important;
      margin-right: -$size !important;
    }
    .-my-#{$name} {
      margin-top: -$size !important;
      margin-bottom: -$size !important;
    }
  }
}
