//.mb-m{
//  display: none;
//}

.dashboard {

}

.dashboard-card {
  border-radius: 10px;
  //padding: 12px;
  //display: flex;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.01), 0 3px 6px 0 rgba(0, 0, 0, 0.1);

  .dashboard-card-content{
    margin-left: 10px;
  }

  .dashboard-card-name{
    font-size: 12px;
  }

  .dashboard-card-value {
    font-size: 20px;
    margin-top: 4px;
  }

}

.dashboard-card-title{
  width: 100%;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.dashboard-card-title-saving{
  background-color: #3498DB;
}

.dashboard-card-title-fund{
  background-color: #2ECC71;
}

.dashboard-card-title-real-estate{
  background-color: #F1C40F;
}

.dashboard-card-user{
  background-color: rgba(231,76,60,0.05);
}

.dashboard-card-user:hover {
  background-color: rgba(231,76,60,0.2);
}

.dashboard-card-savings{
  background-color: rgba(52,152,219,0.05);
}

.dashboard-card-savings:hover {
  background-color: rgba(52,152,219,0.2);
}

.dashboard-card-user-funds{
  background-color: rgba(241,196,15,0.05);
}

.dashboard-card-user-funds:hover {
  background-color: rgba(241,196,15,0.2);
}

.dashboard-card-funds{
  background-color: rgba(46,204,113,0.05);
}

.dashboard-card-funds:hover{
  background-color: rgba(46,204,113,0.2);
}

.dashboard-card-funds-customer{
  background-color: rgba(224, 86, 253, 0.05);
}

.dashboard-card-funds-customer:hover{
  background-color: rgba(224, 86, 253, 0.2);
}

.dashboard-card-saving-customer{
  background-color: rgba(34, 166, 179, 0.05);
}

.dashboard-card-saving-customer:hover{
  background-color: rgba(34, 166, 179, 0.2);
}

.dashboard-card img{
    width: 40px;
    height: 40px;
}

.dashboard-chart-section{
  margin-top: 24px;

  .bar-chart{
    margin-top: 20px;
  }
}

.dashboard-chart-header{
  display: flex;

  .dashboard-chart-title{
    font-size: 18px;
    font-weight: 500;
    flex: 1
  }

  .filter-date-picker{

  }

}

.text-capitalize-first{
  text-transform: lowercase;
}

.text-capitalize-first:first-letter{
  text-transform: capitalize;
}